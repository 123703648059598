import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import Segment from 'components/ui/Segment';

import { getIncreaseColor, getSentimentKpiColor } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';

import * as styleVariables from 'assets/style/variables';

export const formatKpiValue = (value, formatter, isAbsolute) => {
  let formatted = value;
  let suffix = '';
  let sign = '';
  if (typeof formatted === 'number') {
    if (!isAbsolute) {
      formatted *= 100;
      suffix = '%';
    }
    // Remove sign when rounded value is 0.00
    formatted = Math.abs(formatted) < 0.005 ? Math.abs(formatted) : formatted;
    // The threshold is > 0 since we round the value to a 2-precision float and
    // want to avoid -0.03 to be formatted as -0.0
    sign = (formatted > 0.005 && '+') || '';
  }
  return `${sign}${formatter(formatted)}${suffix}`;
};

const KpiRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftKpiColumn = styled.div`
  margin-right: ${styleVariables.spaceNormal};
  max-width: 65%;
  min-width: 0%;

  display: inline-flex;
  align-items: flex-end;
`;
const RightKpiColumn = styled.div`
  text-align: end;
  align-self: flex-end;
  min-width: 30%;
`;

const RightTopKpiColumn = styled.div`
  text-align: end;
  align-self: flex-start;
  font-size: ${styleVariables.fontSizeXLarge};
  font-weight: ${styleVariables.fontWeightMedium};
`;

const KpiContainer = styled(Segment)`
  &&&& {
    width: 100%;
    min-width: 190px;
    max-width: 230px;
    min-height: ${styleVariables.dashboardKpiMinHeight}px;
    padding: ${styleVariables.spaceNormalLarge};
    opacity: ${(props) => (props.disabled ? 0.2 : 1)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

function DashboardKpi({
  name,
  value,
  increase,
  formatter,
  increaseFormatter,
  wrapperStyle,
  disabled,
  absoluteIncrease,
  valueColor,
  withMargins,
  description,
  kpiValueTestId,
}) {
  return (
    <KpiContainer
      style={{
        margin: `${withMargins ? styleVariables.spaceNormalLarge : 0} 0`,
        ...wrapperStyle,
      }}
      disabled={disabled}
    >
      <KpiRow>
        <LeftKpiColumn
          style={{
            fontWeight: styleVariables.fontWeightSemiBold,
            hyphens: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{ paddingRight: styleVariables.spaceNormalLarge }}
            data-testid={`dashboard-kpi-name-${name
              .split(' ')[0]
              .toLowerCase()}`}
          >
            {name}
          </span>
          <HelpTooltip
            position="bottom center"
            mouseEnterDelay={50}
            mouseLeaveDelay={50}
            style={{}}
            help={
              <div>
                <p
                  style={{
                    fontSize: styleVariables.fontSizeSmaller,
                    fontWeight: styleVariables.fontWeightSemiBold,
                  }}
                >
                  {description}
                </p>
                <p style={{ fontSize: styleVariables.fontSizeSmall }}>
                  <i style={{ textDecoration: 'underline' }}>
                    <Trans id="dashboard-kpi.30-days-variation" /> :
                  </i>{' '}
                  <Trans id="dashboard-kpi.30-days-variation-description" />
                </p>
              </div>
            }
            disabled={disabled}
          />
        </LeftKpiColumn>
        <RightTopKpiColumn
          style={{
            color: valueColor || getSentimentKpiColor(value),
          }}
          data-testid={kpiValueTestId}>
          {value !== undefined ? formatter(value) : '-'}
        </RightTopKpiColumn>
      </KpiRow>
      <KpiRow
        style={{
          fontSize: styleVariables.fontSizeSmall,
          lineHeight: styleVariables.lineHeightSmall,
        }}
      >
        <LeftKpiColumn style={{ fontSize: styleVariables.fontSizeSmaller }}>
          <i>
            <Trans id="dashboard-kpi.30-days-variation" />{' '}
          </i>
        </LeftKpiColumn>

        <RightKpiColumn
          style={{
            color: getIncreaseColor(increase, absoluteIncrease),
            fontSize: styleVariables.fontSizeSmaller,
          }}
        >
          {formatKpiValue(increase, increaseFormatter, absoluteIncrease)}
        </RightKpiColumn>
      </KpiRow>
    </KpiContainer>
  );
}

DashboardKpi.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  increase: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatter: PropTypes.func,
  increaseFormatter: PropTypes.func,
  // eslint-disable-next-line
  wrapperStyle: commonPropTypes.style,
  disabled: PropTypes.bool,
  // If set, do not transform increase value as a percentage
  absoluteIncrease: PropTypes.bool,
  // The color of the value - if not set, use absolute sentiment colors
  valueColor: PropTypes.string,
  // Whether margin should be added
  withMargins: PropTypes.bool,
  // Indicator description, added to helper tooltip
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  kpiValueTestId: PropTypes.string,
};
DashboardKpi.defaultProps = {
  formatter: (name) => name,
  increaseFormatter: (name) => name,
  value: undefined,
  increase: undefined,
  disabled: false,
  absoluteIncrease: false,
  valueColor: undefined,
  withMargins: false,
  description: null,
  wrapperStyle: {},
  kpiValueTestId: undefined,
};

export default React.memo(DashboardKpi);
