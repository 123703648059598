import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { switchLanguage } from 'actions/locale';
import { actionTypes } from 'actions/user';
import { LANGUAGES as languages } from 'reducers/locale';
import { createLoadingSelector } from 'reducers/ui';

import { ButtonAccent, ButtonDanger } from 'components/ui/button';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';

import config from 'config';

import * as svars from 'assets/style/variables';

const Container = styled.div`
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: ${svars.spaceMedium};
`;

const Centered = styled.div`
  @media (min-width: 769px) {
    margin: 0 auto;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin: 2rem 0.2rem;
  margin-top: 3rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

function AdminHomePage({ isBw, language, onSwitchLanguage }) {
  const navigate = useNavigate();
  let buttons;
  if (isBw) {
    buttons = (
      <ButtonsContainer>
        <ButtonAccent
          onClick={() => navigate('/admin/live')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
        >
          Live demo
        </ButtonAccent>
        <span style={{ padding: '0.3rem' }} />
        <ButtonAccent
          onClick={() => navigate('/admin/validation')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
        >
          Validate
        </ButtonAccent>
        <ButtonAccent
          onClick={() => navigate('/admin/intentional-error')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
        >
          Intentional error
        </ButtonAccent>
        <ButtonAccent
          onClick={() => navigate('/admin/support')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
        >
          Support
        </ButtonAccent>
        <ButtonDanger
          onClick={() => navigate('/admin/operations')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
        >
          Operations
        </ButtonDanger>
        <ButtonAccent
          onClick={() => navigate('/facets')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
        >
          View Facet List
        </ButtonAccent>
        <ButtonAccent
          onClick={() => navigate('/admin/design')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
        >
          View design system
        </ButtonAccent>
        <ButtonAccent
          onClick={() => navigate('/campaign')}
          style={{ marginBottom: svars.spaceMedium }}
          fluid
          data-testid="bo-homepage-campaign-button"
        >
          <Trans id="campaigns" />
        </ButtonAccent>
      </ButtonsContainer>
    );
  } else {
    buttons = null;
  }
  return (
    <Container>
      <Centered>{buttons}</Centered>
      {!config.FORCE_CUSTOM_LOCALE ? (
        <InTextDropdown
          style={{ minWidth: 0 }}
          options={languages}
          value={language}
          onChange={onSwitchLanguage}
        />
      ) : null}
    </Container>
  );
}

AdminHomePage.propTypes = {
  isBw: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  onSwitchLanguage: PropTypes.func.isRequired,
};

const userLoadingSelector = createLoadingSelector([
  actionTypes.LOGIN_REQUEST,
  actionTypes.WHOAMI_REQUEST,
  actionTypes.LOGOUT_REQUEST,
]);

export default connect(
  (state) => ({
    isBw: state.user.user.isAdmin || state.user.user.isCsm,
    isAuthenticated: state.user.isAuthenticated,
    loading: userLoadingSelector(state),
    language: state.locale.language,
  }),
  (dispatch) => ({
    onSwitchLanguage: (e, { value }) => dispatch(switchLanguage(value)),
  })
)(AdminHomePage);
