import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import ColoredDot from 'components/ui/ColoredDot';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import {
  CellContainer,
  useReactTableColumns,
} from 'components/ui/table/ReactTableColumns';
import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import StyledReactTable from 'components/ui/table/ReactTableUi';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const getRouterLinkCell = (getSearchUrl) => {
  function RouterLinkCell({ value }) {
    return (
      <CellContainer>
        <RouterLink to={getSearchUrl(value)}>
          <AnalyticsAwareHoverableIconButtonWithTooltip
            help={t`see-extracts`}
            name="search"
          />
        </RouterLink>
      </CellContainer>
    );
  }
  RouterLinkCell.propTypes = { value: PropTypes.string.isRequired };
  return RouterLinkCell;
};

const getColoredDotCell = (labelFormatter, colorFormatter) => {
  function ColoredDotCell({ value }) {
    return (
      <CellContainer
        style={{ textAlign: 'left', justifyContent: 'flex-start' }}
      >
        <ColoredDot
          color={colorFormatter(value)}
          style={{
            // Required to make sure dot is always round
            flex: 'none',
            labelFormatter: svars.spaceNormal,
            marginRight: svars.spaceNormal,
          }}
        />
        {labelFormatter(value)}
      </CellContainer>
    );
  }
  ColoredDotCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };
  return ColoredDotCell;
};

const BASE_COLUMNS = (labelFormatter, colorFormatter, getSearchUrl) => ({
  groupColorDot: {
    id: 'product_hierarchy_group_color',
    Header: null,
    accessor: 'product_hierarchy_group',

    maxWidth: 200,
    Cell: getColoredDotCell(labelFormatter, colorFormatter),
    style: {
      textOverflow: 'unset',
      alignSelf: 'center',
      textAlign: 'center',
    },
  },
  n_chunks: {
    id: 'n_chunks',
    Header: () => (
      <ReactTableHeader
        small
        helpMessage={t`help-message.volume-description`}
        title={t`volume`}
      />
    ),
  },
  share_of_extracts: {
    id: 'share_of_extracts',
    Header: () => (
      <ReactTableHeader
        small
        helpMessage={t`help-message.share-of-extracts-description`}
        title={t`share-of-extracts`}
      />
    ),
    minWidth: 140,
  },
  average_sentiment: {
    id: 'average_sentiment',
    minWidth: 100,
    maxWidth: 100,
    Header: () => (
      <ReactTableHeader
        small
        helpMessage={t`help-message.average-sentiment-description`}
        title={t`sentiment`}
      />
    ),
  },
  differential_sentiment: {
    id: 'differential_sentiment',
    accessor: 'differentialSentiment',
    minWidth: 170,
    maxWidth: 170,
    Header: () => (
      <ReactTableHeader
        small
        helpMessage={t`help-message.benchmark-differential-sentiment-description`}
        title={t`differential-sentiment`}
      />
    ),
  },
  search: {
    accessor: 'product_hierarchy_group',
    centered: true,
    minWidth: 20,
    width: 20,
    maxWidth: 20,
    Cell: getRouterLinkCell(getSearchUrl),
    style: {
      textOverflow: 'unset',
      alignSelf: 'center',
      textAlign: 'center',
    },
  },
});

function CompetitionConceptTable({
  baseKpi,
  competitionConceptKpis,
  getSearchUrl,
  labelFormatter,
  colorFormatter,
}) {
  const data = [
    { ...baseKpi, differentialSentiment: null },
    ...competitionConceptKpis.map((kpi) => ({
      ...kpi,
      differentialSentiment:
        baseKpi.average_sentiment && kpi.average_sentiment
          ? kpi.average_sentiment - baseKpi.average_sentiment
          : null,
    })),
  ];
  const maxNChunks = Math.max(...data.map((row) => row.n_chunks));
  const baseColumns = BASE_COLUMNS(
    labelFormatter,
    colorFormatter,
    getSearchUrl
  );
  const columns = useMemo(
    () => useReactTableColumns(baseColumns, maxNChunks),
    [baseColumns, maxNChunks]
  );
  return (
    <StyledReactTable
      data={data}
      sortable={false}
      columns={columns}
      i18nNoDataText={t`no-groups-to-show`}
      style={{ maxHeight: 'inherit' }}
    />
  );
}

CompetitionConceptTable.propTypes = {
  baseKpi: commonPropTypes.kpiPropTypes.isRequired,
  competitionConceptKpis: PropTypes.arrayOf(
    commonPropTypes.productHierarchyDistributionItem
  ).isRequired,
  labelFormatter: PropTypes.func.isRequired,
  colorFormatter: PropTypes.func.isRequired,
  getSearchUrl: PropTypes.func.isRequired,
};

export default CompetitionConceptTable;
