import { useCallback, useState } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import AlternativeTimeSeriesVisualizations from 'components/customer/visualization/AlternativeTimeSeriesVisualizations';
import ScatterChartWithExportModal from 'components/customer/visualization/scatter-chart/ScatterChartWithExportModal';
import { NoMarginGrid } from 'components/ui/Grid';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import { I18nDropdown } from 'components/ui/inputs/Dropdown';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const AGGREGATED_VIEW_EXPORT_ID = 'benchmark-aggregated-view';

export const TS_DISPLAY_OPTIONS = [
  {
    key: 3,
    value: 'average_sentiment',
    i18nText: t`sentiment`,
  },
  {
    key: 1,
    value: 'cumulated',
    i18nText: t`cumulated-volume`,
  },
];
export const TS_DISPLAY_OPTIONS_WITH_SHARE_OF_EXTRACTS = [
  ...TS_DISPLAY_OPTIONS,
  {
    key: 2,
    value: 'share_of_extracts',
    i18nText: t`share-of-extracts`,
  },
];
function BenchmarkMultiChart({
  baseColor,
  baseLabel,
  baseTimeSeries,
  benchmarkConceptPositioning,
  chartId,
  chartTitle,
  colorFormatter,
  competitionTimeSeries,
  conceptAlternativeTimeSeriesDisplayOptionValue,
  height,
  highlightScatterData,
  labelFormatter,
  loading,
  onBenchmarkConceptPositioningChange,
  onDisplayOptionValueChange,
  scatterData,
  timeSeriesDisplayOptions,
}) {
  const [exportChartModalIsOpen, setExportChartModalIsOpen] = useState(false);
  const toggleExportChartModalIsOpen = useCallback(
    () => setExportChartModalIsOpen(!exportChartModalIsOpen),
    [exportChartModalIsOpen, setExportChartModalIsOpen]
  );
  const chartColorFormatter = useCallback(
    (item) => colorFormatter(item.product_hierarchy_group),
    [colorFormatter]
  );
  const legendWrapperStyles = {
    paddingLeft: svars.spaceMediumLarge,
    maxWidth: '33%',
    maxHeight: '80%',
    overflow: 'auto',
  };
  return (
    <NoMarginGrid>
      <Grid.Row style={{ minHeight: '54px' }}>
        <Grid.Column width={8}>
          <ButtonGroup
            as={SecondaryTabButton}
            items={[
              {
                key: 'aggregated',
                i18nLabel: t`aggregated-view`,
                value: 'aggregated',
              },
              {
                key: 'evolution',
                i18nLabel: t`evolution`,
                value: 'evolution',
              },
            ]}
            onChange={onBenchmarkConceptPositioningChange}
            value={benchmarkConceptPositioning}
          />
        </Grid.Column>
        {benchmarkConceptPositioning === 'evolution' ? (
          <Grid.Column width={6} floated="right">
            <I18nDropdown
              small="true"
              selection
              options={timeSeriesDisplayOptions}
              onChange={(e, { value }) => onDisplayOptionValueChange(value)}
              value={conceptAlternativeTimeSeriesDisplayOptionValue}
            />
          </Grid.Column>
        ) : (
          <Grid.Column width={1} floated="right" verticalAlign="bottom">
            <ExportAsImage
              onClick={toggleExportChartModalIsOpen}
              exportName={chartTitle}
              disabled={loading}
              exportAsImageTestId="bo-benchmark-export-as-image-icon"
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column verticalAlign="middle">
          {benchmarkConceptPositioning === 'evolution' ? (
            <AlternativeTimeSeriesVisualizations
              displayOptions={timeSeriesDisplayOptions}
              displayOptionValue={
                conceptAlternativeTimeSeriesDisplayOptionValue
              }
              baseTimeSeries={baseTimeSeries}
              baseLabel={baseLabel}
              baseColor={baseColor}
              colorFormatter={colorFormatter}
              competitionTimeSeriesMonthly={competitionTimeSeries}
              height={height}
              loading={loading}
              legendWrapperStyles={legendWrapperStyles}
              legendAlign="right"
            />
          ) : (
            <ScatterChartWithExportModal
              data={[
                ...(scatterData || []),
                ...((highlightScatterData && [highlightScatterData]) || []),
              ]}
              getRadius={svars.getPercentScatterRadius('share_of_extracts')}
              radiusTooltipFieldKey="share_of_extracts"
              labelFormatter={labelFormatter}
              colorFormatter={chartColorFormatter}
              height={height}
              loading={loading}
              categoryDataKey="product_hierarchy_group"
              legendWrapperStyles={legendWrapperStyles}
              fillOpacity={0.7}
              chartId={chartId}
              modalIsOpen={exportChartModalIsOpen}
              onCloseModal={toggleExportChartModalIsOpen}
              exportName={chartTitle}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </NoMarginGrid>
  );
}

BenchmarkMultiChart.propTypes = {
  benchmarkConceptPositioning: PropTypes.string.isRequired,
  conceptAlternativeTimeSeriesDisplayOptionValue: PropTypes.string.isRequired,
  baseLabel: PropTypes.string.isRequired,
  baseColor: PropTypes.string.isRequired,
  baseTimeSeries: commonPropTypes.timeSeries,
  competitionTimeSeries: PropTypes.objectOf(commonPropTypes.timeSeries),
  scatterData: PropTypes.arrayOf(commonPropTypes.kpiPropTypes),
  highlightScatterData: commonPropTypes.kpiPropTypes,
  onBenchmarkConceptPositioningChange: PropTypes.func.isRequired,
  onDisplayOptionValueChange: PropTypes.func.isRequired,
  labelFormatter: PropTypes.func.isRequired,
  colorFormatter: PropTypes.func.isRequired,
  height: PropTypes.number,
  loading: PropTypes.bool,
  // Chart title - used only to name export file
  chartTitle: PropTypes.string,
  // Chart title - used to identify chart to export
  chartId: PropTypes.string,
  // Display options for the alternative time series
  timeSeriesDisplayOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      i18nText: PropTypes.string.isRequired,
    })
  ),
};

BenchmarkMultiChart.defaultProps = {
  height: svars.chartHeight,
  scatterData: undefined,
  baseTimeSeries: undefined,
  competitionTimeSeries: undefined,
  highlightScatterData: undefined,
  chartTitle: undefined,
  loading: false,
  chartId: AGGREGATED_VIEW_EXPORT_ID,
  timeSeriesDisplayOptions: TS_DISPLAY_OPTIONS_WITH_SHARE_OF_EXTRACTS,
};

export default BenchmarkMultiChart;
